import {
  Typography
} from "@mui/material";
import {
  QuestionAnswer as QuestionAnswerIcon,
  Balance as BalanceIcon
} from "@mui/icons-material";

const textGenerationModeCatalog = [
  "@cf/meta/llama-2-7b-chat-fp16",
  "@cf/meta/llama-2-7b-chat-int8",
  "@cf/mistral/mistral-7b-instruct-v0.1",
  "@hf/thebloke/deepseek-coder-6.7b-base-awq",
  "@hf/thebloke/deepseek-coder-6.7b-instruct-awq",
  "@cf/deepseek-ai/deepseek-math-7b-base",
  "@cf/deepseek-ai/deepseek-math-7b-instruct",
  "@cf/thebloke/discolm-german-7b-v1-awq",
  "@cf/tiiuae/falcon-7b-instruct",
  "@cf/google/gemma-2b-it-lora",
  "@hf/google/gemma-7b-it",
  "@cf/google/gemma-7b-it-lora",
  "@hf/nousresearch/hermes-2-pro-mistral-7b",
  "@hf/thebloke/llama-2-13b-chat-awq",
  "@cf/meta-llama/llama-2-7b-chat-hf-lora",
  "@cf/meta/llama-3-8b-instruct",
  "@cf/meta/llama-3-8b-instruct-awq",
  "@cf/meta/llama-3.1-8b-instruct",
  "@hf/thebloke/llamaguard-7b-awq",
  "@hf/thebloke/mistral-7b-instruct-v0.1-awq",
  "@hf/mistral/mistral-7b-instruct-v0.2",
  "@cf/mistral/mistral-7b-instruct-v0.2-lora",
  "@hf/thebloke/neural-chat-7b-v3-1-awq",
  "@cf/openchat/openchat-3.5-0106",
  "@hf/thebloke/openhermes-2.5-mistral-7b-awq",
  "@cf/microsoft/phi-2",
  "@cf/qwen/qwen1.5-0.5b-chat",
  "@cf/qwen/qwen1.5-1.8b-chat",
  "@cf/qwen/qwen1.5-14b-chat-awq",
  "@cf/qwen/qwen1.5-7b-chat-awq",
  "@cf/defog/sqlcoder-7b-2",
  "@hf/nexusflow/starling-lm-7b-beta",
  "@cf/tinyllama/tinyllama-1.1b-chat-v1.0",
  "@cf/fblgit/una-cybertron-7b-v2-bf16",
  "@hf/thebloke/zephyr-7b-beta-awq"
];

/*
const textToImageModelCatalog = [
  "@cf/lykon/dreamshaper-8-lcm",
  "@cf/runwayml/stable-diffusion-v1-5-img2img",
  "@cf/runwayml/stable-diffusion-v1-5-inpainting",
  "@cf/stabilityai/stable-diffusion-xl-base-1.0",
  "@cf/bytedance/stable-diffusion-xl-lightning"
];
*/

export const textGenerationModelList = [...textGenerationModeCatalog].sort(
  (a: string, b: string): number => a.localeCompare(b)
);

// AIの会社リストを生成する関数
export const groupTextGenerationModelList = (models: string[]): Record<string, string[]> => {
  // 会社名でグループ化
  const groupedModels = models.reduce((acc, model) => {
    const company = model.split('/')[1];
    if (!acc[company]) {
      acc[company] = [];
    }
    acc[company].push(model);
    return acc;
  }, {} as Record<string, string[]>);

  // 会社名を名前順でソートする
  const sortedGroupedModels: Record<string, string[]> = {};
  Object.keys(groupedModels).sort().forEach(key => {
    sortedGroupedModels[key] = groupedModels[key];
  });

  return sortedGroupedModels;
};

export const groupedTextGenerationModelList: Record<string, string[]> = groupTextGenerationModelList(textGenerationModelList);

// メイン画面のナビゲーション
export const navigationList = [
  { label: "生成AI知恵比べ", icon: <BalanceIcon /> },
  { label: "生成AIチャット", icon: <QuestionAnswerIcon /> }
];

const listStyle = {
  paddingLeft: 16
};
// ヘルプに表示する内容
export const helpList = [
  {
    summary: "ポイントについて",
    details: (
      <div>
        <Typography variant="caption">
          <ul style={listStyle}><li>
            {"所有ポイントは、毎朝9時を過ぎた後に回復するよ！回復しない場合はページをリロードしてね！"}
          </li><li>
              {`所有ポイントは他のAIアプリと連動しているよ！`}
            </li></ul>
        </Typography>
      </div>
    )
  },
  {
    summary: "使用AIモデル",
    details: (
      <div>
        <Typography variant="caption">
          <ul style={listStyle}><li>
            {"翻訳：Meta / m2m100 1.2B"}
          </li></ul>
        </Typography>
      </div>
    )
  },
  {
    summary: "プライバシーポリシー",
    details: (
      <div>
        <Typography variant="caption">
          <ol style={listStyle}><li>
            {"Google Analyticsについて"}
            <ul style={listStyle}><li>
              {"当サイトではGoogle Analyticsを利用しています。"}
            </li><li>
                {"トラフィックデータの収集のためにCookieを使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。"}
              </li></ul>
          </li></ol>
        </Typography>
      </div>
    )
  },
  {
    summary: "利用規約",
    details: (
      <div>
        <Typography variant="caption">
          <ol style={listStyle}><li>
            {"リンクについて"}
            <ul style={listStyle}><li>
              {"当ブログは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。"}
            </li><li>
                {"ただし、インラインフレームの使用や画像の直リンクは禁止します。"}
              </li></ul>
          </li><li>
              {"免責事項について"}
              <ul style={listStyle}><li>
                {"当サイトの情報や、当サイトのリンクやバナーなどで移動したサイトで提供される情報やサービス等について、損害等の一切の責任を負いません。"}
              </li><li>
                  {"できる限り正確な情報を提供するように努めていますが、正確性や安全性を保証するものではありません。"}
                </li></ul>
            </li><li>
              {"著作権について"}
              <ul style={listStyle}><li>
                {"当サイトで掲載している文章や画像などにつきましては、無断転載することを禁止します。"}
              </li><li>
                  {"当ブログは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がある場合、お問い合わせフォームよりご連絡ください。"}
                </li></ul>
            </li></ol>
        </Typography>
      </div>
    )
  }
];
