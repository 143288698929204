import { useState, useMemo } from "react";
import { logEvent } from "firebase/analytics";
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import rehypeExternalLinks from 'rehype-external-links';
import {
  Grid, Box,
  Accordion, AccordionSummary, AccordionDetails,
  Checkbox, FormControlLabel, FormGroup,
  Switch,
  TextField, Button,
  Card, CardContent,
  Divider,
  Typography
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Send as SendIcon
} from "@mui/icons-material";
import {
  getCurrentUser
} from "../firebase/Authentication-Google";
import { analytics } from "../firebase/Analytics";
import { fetchPost } from "../utils/FetchFunctions";
import { useStateContext } from "../utils/StateContext";
import { textGenerationModelList, groupedTextGenerationModelList } from "../utils/Config";

type ExtendedCSSProperties = React.CSSProperties & { [key: string]: any };
type StyleObjectType = { [key: string]: ExtendedCSSProperties };
type TextGenerationModel = typeof textGenerationModelList[number];
type OutputList = {
  model: TextGenerationModel;
  message: string;
};

function TextGeneration(): JSX.Element {
  const {
    createMessage,
    isRunning,
    setIsRunning,
    isSignIn,
    points,
    setPoints,
    setUpdatedDate
  } = useStateContext();

  // 環境変数から文章生成で消費するポイントを取得
  const textGenerationPoints = Number(process.env.REACT_APP_textGenerationPoints);
  // 環境変数から翻訳で消費するポイントを取得
  const translationPoints = Number(process.env.REACT_APP_translationPoints);
  // 環境変数から最大文字数を取得
  const maxTextLength = Number(process.env.REACT_APP_maxTextLength);
  // 環境変数から最大モデル選択数を取得
  const maxSelectedModel = Number(process.env.REACT_APP_maxSelectedModel);
  // インプット
  const [input, setInput] = useState<string>("");
  // 選択されたモデルのリスト
  const [selectedModelList, setSelectedModelList] = useState<TextGenerationModel[]>([
    "@hf/google/gemma-7b-it",
    "@cf/meta/llama-3.1-8b-instruct",
    "@cf/microsoft/phi-2"
  ]);
  // 入力言語を日本語にするか
  const [isInputJp, setIsInputJp] = useState<boolean>(false);
  // 出力言語を日本語にするか
  const [isOutputJp, setIsOutputJp] = useState<boolean>(false);
  // アウトプットのリスト
  const [outputList, setOutputList] = useState<OutputList[]>([]);

  // 文章生成で消費されるポイント
  const usedPoints = useMemo(() => {
    return textGenerationPoints * selectedModelList.length + translationPoints * (Number(isOutputJp) + Number(isInputJp));
  }, [selectedModelList, isInputJp, isOutputJp, textGenerationPoints, translationPoints]);

  // 文章生成後のポイント
  const newPoints = useMemo(() => {
    return points - usedPoints;
  }, [points, usedPoints]);

  // モデル選択の処理
  const handleModelChange = (model: TextGenerationModel) => {
    setSelectedModelList(prev => {
      if (prev.includes(model)) {
        return prev.filter(m => m !== model);
      } else if (prev.length < maxSelectedModel) {
        return [...prev, model];
      } else {
        createMessage(`最大${maxSelectedModel}つまで選択できます。`, "warning");
        return prev;
      }
    });
  };

  const start = async () => {
    setIsRunning(true);
    createMessage("送信中...", "info");
    logEvent(analytics, "文章生成の「送信」ボタン押下", {
      app: "maitake-gai",
      module: "pages/TextGeneration.tsx",
      function: "start"
    });

    // 現在サインインしているユーザを取得
    const currentUser = getCurrentUser();
    if (!currentUser) {
      createMessage("サインインしていないよ。", "error");
      setIsRunning(false);
      return;
    }

    // サインインしているユーザのuidを取得
    const uid = currentUser.uid;

    setOutputList([]);

    // 文章生成
    const data = await fetchPost("textGeneration", uid, input, selectedModelList, isInputJp, isOutputJp);
    if (data === undefined) {
      createMessage("送信失敗。", "error");
      setIsRunning(false);
      return;
    }
    setPoints(data.points);
    setUpdatedDate(data.updatedDate);
    setOutputList(data.outputList);
    createMessage(data.message, (data.result ? "success" : "error"));
    setIsRunning(false);
  };

  return (
    <div>
      <Typography
        sx={{ mt: 2 }}
      >
        {`消費ポイント：${usedPoints} pt`}
      </Typography>
      <Typography variant="caption"
        sx={{ mt: 2 }}
      >
        {`${textGenerationPoints} pt × ${selectedModelList.length} (AIモデル選択数） + ${translationPoints} pt × ${Number(isOutputJp) + Number(isInputJp)}（翻訳回数）`}
      </Typography>

      <Grid container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "start",
          textAlign: "center",
        }}
      >
        <Grid item xs={12}
          sx={{ mt: 2 }}
        >
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <Accordion variant="outlined">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      {`AIモデルを選択 (${selectedModelList.length}/${maxSelectedModel})`}
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(groupedTextGenerationModelList).map(company => (
                        <Accordion
                          key={company}
                          variant="outlined"
                          defaultExpanded={selectedModelList.some(model =>
                            groupedTextGenerationModelList[company].includes(model)
                          )}
                          disabled={selectedModelList.some(model =>
                            groupedTextGenerationModelList[company].includes(model)
                          )}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                          >
                            {company}
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup row
                              sx={{
                                textAlign: "start"
                              }}
                            >
                              {groupedTextGenerationModelList[company].map((model) => (
                                <FormControlLabel
                                  key={model}
                                  control={
                                    <Checkbox
                                      checked={selectedModelList.includes(model)}
                                      onChange={() => handleModelChange(model)}
                                      disabled={!selectedModelList.includes(model) && selectedModelList.length >= maxSelectedModel}
                                    />
                                  }
                                  label={model.split('/')[2]}
                                />
                              ))}
                            </FormGroup>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                  <FormGroup row
                    sx={{
                      justifyContent: "space-around",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isInputJp}
                          onChange={() => setIsInputJp(prev => !prev)}
                          size="small"
                        />
                      }
                      label="入力日英翻訳"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isOutputJp}
                          onChange={() => setIsOutputJp(prev => !prev)}
                          size="small"
                        />
                      }
                      label="出力英日翻訳"
                    />
                  </FormGroup>

                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      textAlign: "end"
                    }}
                  >
                    {`${input.length} / ${maxTextLength}文字`}
                  </Typography>

                  <TextField
                    label={"文章を入力"}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    required
                    disabled={isRunning}
                    error={input.length > maxTextLength}
                    variant="outlined"
                    multiline
                    rows={9}
                    fullWidth
                    sx={{ mt: 1 }}
                  />

                  <Button
                    onClick={start}
                    disabled={isRunning
                      || !isSignIn
                      || !(selectedModelList.length > 0 && selectedModelList.length <= maxSelectedModel)
                      || !input
                      || (input.length > maxTextLength)
                      || (newPoints < 0)
                    }
                    startIcon={<SendIcon />}
                    fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    {`送信`}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
          {outputList && outputList.length > 0 &&
            outputList.map((output) => (
              <Grid item xs={12} md={4} key={output.model}>
                <Card>
                  <CardContent>
                    <Typography variant="caption">
                      {`${output.model.split('/')[1]} / ${output.model.split('/')[2]}`}
                    </Typography>
                    <Divider sx={{ mb: 1 }} />
                    <Box sx={{ textAlign: "start" }}>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeExternalLinks]}
                        components={{
                          code({ node, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '');
                            const codeString = String(children).replace(/\n$/, '');

                            if (!match) {
                              return <code className={className} {...props}>{codeString}</code>;
                            }

                            return (
                              <SyntaxHighlighter
                                style={vscDarkPlus as StyleObjectType}
                                language={match[1]}
                                PreTag="div"
                                {...props as any}
                              >
                                {codeString}
                              </SyntaxHighlighter>
                            );
                          }
                        }}
                      >
                        {output.message}
                      </ReactMarkdown>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
      </Grid>
    </div>
  );
}

export default TextGeneration;
