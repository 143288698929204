import { useEffect, useMemo } from "react";
import {
  Container,
  Typography
} from "@mui/material";
import {
  onAuthStateChangedGoogle,
  getCurrentUser
} from "../firebase/Authentication-Google";
import { fetchGet } from "../utils/FetchFunctions";
import { useStateContext } from "../utils/StateContext";
import SignIn from "../components/SignIn";
import MainAppBar from "../components/MainAppBar";
import TextGeneration from "./TextGeneration";

function Main(): JSX.Element {
  const {
    createMessage,
    setIsRunning,
    isSignIn,
    setIsSignIn,
    points,
    setPoints,
    updatedDate,
    setUpdatedDate
  } = useStateContext();

  // メイン画面が読み込まれた時にサインインしているか確認
  useEffect(() => {
    (async () => {
      setIsRunning(true);
      createMessage("読み込み中...", "info");

      // Googleアカウントでサインインしているか確認
      const authResult = await onAuthStateChangedGoogle();
      setIsSignIn(authResult);

      if (!authResult) {
        createMessage("サインインしていないよ。", "warning");
        setIsRunning(false);
        return;
      }

      createMessage("サインインしているよ！", "success");
      setIsRunning(false);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (!isSignIn) {
        return;
      }

      setIsRunning(true);
      createMessage("読み込み中...", "info");

      // 現在サインインしているユーザを取得
      const currentUser = getCurrentUser();
      if (!currentUser) {
        createMessage("サインインしていないよ。", "warning");
        setIsRunning(false);
        return;
      }

      // サインインしているユーザのuidを取得
      const uid = currentUser.uid;
      const data = await fetchGet("readPoints", uid);

      if (data === undefined) {
        createMessage("データ取得失敗。", "error");
        setIsRunning(false);
        return;
      }
      setPoints(data.points);
      setUpdatedDate(data.updatedDate);
      createMessage("データ取得成功！", "success");
      setIsRunning(false);
    })();
    // eslint-disable-next-line
  }, [isSignIn]);

  // 表示する最終利用日時
  const updatedDateDisplay: string = useMemo(() => {
    if (updatedDate === "") {
      return "";
    }
    return new Date(updatedDate).toLocaleString("ja-JP");
  }, [updatedDate]);

  return (
    <div>
      <MainAppBar />

      <Container maxWidth="xl" sx={{ mb: 2 }}>
        {(!isSignIn)
          ?
          <SignIn />
          :
          <div>
            <Typography
              sx={{ mt: 2 }}
            >
              {`最終利用日時：${updatedDateDisplay}`}
            </Typography>

            <Typography>
              {`所有ポイント：${points} pt / ${process.env.REACT_APP_initialPoints} pt`}
            </Typography>

            <TextGeneration />
          </div>
        }
      </Container>
    </div>
  );
}

export default Main;
